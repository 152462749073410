import React from "react";
import { graphql } from "gatsby";
import { PageContext } from "~/components/Pagination";
import { Layout, PostCard, Pagination } from "../components";
import { MetaData } from "../components/meta";

type Props = {
  data: { allGhostPost: Record<string, unknown> };
  location: Location;
  pageContext: PageContext;
};

/**
 * Main index page (home page)
 *
 * Loads all posts from Ghost and uses pagination to navigate through them.
 * The number of posts that should appear per page can be setup
 * in /utils/siteConfig.js under `postsPerPage`.
 */
const Index = ({ data, location, pageContext }) => {
  const posts = data.allGhostPost.edges;

  return (
    <>
      <MetaData location={location} />
      <Layout location={location}>
        <section className="post-feed grid justify-between gap-10">
          {posts.map(({ node }) => (
            <PostCard key={node.id} post={node} />
          ))}
        </section>
        <Pagination pageContext={pageContext} />
      </Layout>
    </>
  );
};

export default Index;

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostPostQuery($limit: Int!, $skip: Int!) {
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`;
